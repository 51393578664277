define("discourse/plugins/discourse-tooltips/discourse/lib/event-from", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setEventFrom = _exports.eventFrom = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /* eslint-disable */
  // prettier-ignore
  var recentEventFrom = "key";
  var recentFocusFrom = recentEventFrom;
  var recentTouch = false;
  var recentMouse = false;
  var recentWindowFocus = false; // To determine if there was a recentTouch event
  // use setTimeout instead of a Date.now() comparison because
  // in the case of a long running/blocking process from a touch event,
  // the browser will push the corresponding mouse event (created by the touch interaction)
  // onto the callback queue at the time it should be executed,
  // and then push the timeout function onto the queue after the timer expires,
  // even if the the main thread is still blocked (because the browser is multi-threaded).
  // This results in the mouse event being moved to the callstack and called
  // before the timeout function so recentTouch is still true
  // regardless of how many Date.now() seconds have gone by.
  // Also, if subsequent touch events occur while the blocking process is running,
  // the browser will push the touch events onto the queue when the touch happens,
  // and if one of them is in queue before the previous touch event's timer expires,
  // it will be called before the timeout's function (so it can reset the timer),
  // and, this is the key part, if the previous timer has finished and it's callback is added to the queue,
  // the call to clearTimeout(recentTouchTimeoutId) will remove the timeout's function from the callback queue.

  var recentTouchTimeoutId;

  var setRecentEventFromTouch = function setRecentEventFromTouch(touchDelay) {
    recentTouch = true;
    recentEventFrom = "touch";
    window.clearTimeout(recentTouchTimeoutId);
    recentTouchTimeoutId = window.setTimeout(function () {
      recentTouch = false;
    }, touchDelay);
  };

  var recentMouseTimeoutId;

  var setRecentEventFromMouse = function setRecentEventFromMouse() {
    recentMouse = true;
    recentEventFrom = "mouse";
    window.clearTimeout(recentMouseTimeoutId);
    recentMouseTimeoutId = window.setTimeout(function () {
      recentMouse = false;
    }, 200);
  };

  var handleTouchEvent = function handleTouchEvent(touchDelay) {
    return function () {
      return setRecentEventFromTouch(touchDelay);
    };
  };

  var handlePointerEvent = function handlePointerEvent(touchDelay) {
    return function (e) {
      switch (e.pointerType) {
        case "mouse":
          setRecentEventFromMouse();
          break;

        case "pen":
        case "touch":
          setRecentEventFromTouch(touchDelay);
          break;
      }
    };
  };

  var handleMouseEvent = function handleMouseEvent() {
    if (!recentTouch) {
      setRecentEventFromMouse();
    }
  };

  var handleKeyEvent = function handleKeyEvent() {
    recentEventFrom = "key";
  }; // recentFocusFrom tracking
  // set document focus event capture listener which sets recentFocusFrom equal to recentEventFrom
  // except if there is a recent window focus event where the window is the target (unless there is also a recent mouse or touch event),
  // in which case leave recentFocusFrom unchanged to maintain correct recentFocusFrom after switching apps/windows/tabs/etc,
  // if/when the focus event is passed into eventFrom later in the cycle, just return recentFocusFrom.
  // for tracking recent window focus, set window focus capture event listener,
  // if the target is window (or document on firefox), then track recentWindowFocus with setTimeout 300


  var recentWindowFocusTimeoutId;

  var handleWindowFocusEvent = function handleWindowFocusEvent(e) {
    if (e.target === window || e.target === document) {
      recentWindowFocus = true;
      window.clearTimeout(recentWindowFocusTimeoutId);
      recentWindowFocusTimeoutId = window.setTimeout(function () {
        recentWindowFocus = false;
      }, 300);
    }
  };

  var handleDocumentFocusEvent = function handleDocumentFocusEvent() {
    if (!recentWindowFocus || recentMouse || recentTouch) {
      recentFocusFrom = recentEventFrom;
    }
  };

  var listenerOptions = {
    capture: true,
    passive: true
  };
  var documentListeners = [["touchstart", handleTouchEvent(750)], ["touchend", handleTouchEvent(300)], ["touchcancel", handleTouchEvent(300)], ["pointerenter", handlePointerEvent(300)], ["pointerover", handlePointerEvent(300)], ["pointerout", handlePointerEvent(300)], ["pointerleave", handlePointerEvent(300)], ["pointerdown", handlePointerEvent(750)], ["pointerup", handlePointerEvent(300)], ["pointercancel", handlePointerEvent(300)], ["mouseenter", handleMouseEvent], ["mouseover", handleMouseEvent], ["mouseout", handleMouseEvent], ["mouseleave", handleMouseEvent], ["mousedown", handleMouseEvent], ["mouseup", handleMouseEvent], ["keydown", handleKeyEvent], ["keyup", handleKeyEvent], ["focus", handleDocumentFocusEvent]];

  if (typeof window !== "undefined" && typeof document !== "undefined") {
    documentListeners.forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          eventName = _ref2[0],
          eventHandler = _ref2[1];

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore not sure how to get TS to match the handler type to the specific eventName
      document.addEventListener(eventName, eventHandler, listenerOptions);
    });
    window.addEventListener("focus", handleWindowFocusEvent, listenerOptions);
  } // temporarily set the return value for eventFrom(e)
  // note that the eventFrom(e) value will change when new events come in
  // useful when manually generating events, e.g. el.focus() or el.click()
  // and you want eventFrom(e) to treat that event as occurring from a specific input


  var setEventFrom = function setEventFrom(value) {
    if (process.env.NODE_ENV !== "production") {
      if (value !== "mouse" && value !== "touch" && value !== "key") {
        throw Error("setEventFrom function requires argument of \"mouse\" | \"touch\" | \"key\", argument received: ".concat(value));
      }
    }

    if (value === "mouse" || value === "touch" || value === "key") {
      recentEventFrom = value;
      recentFocusFrom = value;
    }
  }; // use any instead of unknown b/c unknown causes type error when passing a react synthetic event
  // eslint-disable-next-line @typescript-eslint/no-explicit-any


  _exports.setEventFrom = setEventFrom;

  var eventFrom = function eventFrom(event) {
    // use the incoming event to help determine recentEventFrom
    // in the same manner as the document event listeners
    // this helps catch edge cases especially when a move event is passed to eventFrom
    // because move event listeners are not set by Event From
    switch (event.pointerType) {
      case "mouse":
        setRecentEventFromMouse();
        break;

      case "pen":
      case "touch":
        if (!recentTouch) {
          setRecentEventFromTouch(300);
        } else {
          recentEventFrom = "touch";
        }

        break;
    }

    if (/mouse/.test(event.type) && !recentTouch) {
      setRecentEventFromMouse();
    }

    if (/touch/.test(event.type)) {
      if (!recentTouch) {
        setRecentEventFromTouch(300);
      } else {
        recentEventFrom = "touch";
      }
    } // focus events return recentFocusFrom, see recentFocusFrom tracking note above


    if (/focus/.test(event.type)) {
      return recentFocusFrom;
    }

    return recentEventFrom;
  }; // note that edge cases exist for scroll and wheel events where eventFrom will return the wrong input,
  // to fix this, event-from would need to set a 'wheel' event listener on the document (see below),
  // but decided not to add it because 'wheel' is a high frequency event (like move events)
  // and don't currently have a use case for eventFrom(scrollEvent)
  // to add support:
  //   document.addEventListener(
  //     'wheel',
  //     () => {
  //       // might need to track wheel event separately and use it just for eventFrom(scroll)
  //       // because the wheel event is elastic, it continues to fire after the user interaction has finished
  //       recentEventFrom = 'mouse';
  //     },
  //     listenerOptions,
  //   );


  _exports.eventFrom = eventFrom;
});
define("discourse/plugins/discourse-tooltips/initializers/attach-hover-event", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-tooltips/mixins/topic-hover-extension"], function (_exports, _pluginApi, _topicHoverExtension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "attach-hover-event",
    initialize: function initialize() {
      (0, _pluginApi.withPluginApi)("0.8.9", function (api) {
        api.modifyClass("component:topic-list", (0, _topicHoverExtension.hoverExtension)(".raw-topic-link"));
        api.modifyClass("component:categories-topic-list", (0, _topicHoverExtension.hoverExtension)(".main-link a.title"));
      });
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-tooltips/mixins/topic-hover-extension", ["exports", "discourse/lib/ajax", "discourse-common/lib/object", "discourse/plugins/discourse-tooltips/discourse/lib/event-from"], function (_exports, _ajax, _object, _eventFrom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hoverExtension = hoverExtension;
  // How many extra post excerpts to retrieve
  var READ_AHEAD = 4;
  var _cached = {};

  var _promise;

  var _inside = false;

  function cleanDom() {
    $(".d-tooltip").remove();
    _inside = false;
  }

  function cancel() {
    if (_promise) {
      _promise.abort();

      _promise = null;
    }

    cleanDom();
  }

  function renderTooltip($this, text) {
    $this.after("<div class='d-tooltip'><div class='d-tooltip-pointer'></div><div class='d-tooltip-content'>".concat(text, "</div></div></div>"));
    var $dTooltip = $(".d-tooltip");
    var tooltipWidth = $dTooltip.outerWidth();
    var elementWidth = $this.width();
    var elementHeight = $this.height();
    var elementPos = $this.position();
    var elementX = elementPos.left;
    var y = elementPos.top + elementHeight;
    var x = elementX + elementWidth / 2 - tooltipWidth / 2; // make sure left side of the tooltip is not out of the screen

    var $mainLink = $this.hasClass("main-link") ? $this : $this.parents(".main-link");
    var mainLinkLeftOffset = $mainLink.offset().left;

    if (mainLinkLeftOffset + x < 0) {
      x = elementX;
    }

    $dTooltip.css({
      left: "".concat(x, "px"),
      top: "".concat(y, "px")
    });
    $dTooltip.fadeIn(200);
  }

  var tooltipsRateLimited = false;

  function hoverExtension(selector) {
    return {
      pluginId: "discourse-tooltips",
      didInsertElement: function didInsertElement() {
        this._super.apply(this, arguments);

        cancel();
        $(this.element).on("mouseenter.discourse-tooltips", selector, function (e) {
          var _this = this;

          if ((0, _eventFrom.eventFrom)(e) !== "mouse") {
            return;
          }

          var $this = $(this);
          var $parentTopicId = $(e.currentTarget);

          if (typeof $parentTopicId.attr("data-topic-id") === "undefined") {
            $parentTopicId = $parentTopicId.parents("[data-topic-id]").last();
          }

          var topicId = parseInt($parentTopicId.attr("data-topic-id"), 10);

          if (topicId) {
            cancel();
            _inside = true;

            if (_cached[topicId]) {
              return renderTooltip($this, _cached[topicId].excerpt);
            }

            if (tooltipsRateLimited) {
              return;
            }

            var topicIds = [topicId]; // Let's actually fetch the next few topic ids too, assuming the user will
            // mouse over more below

            var $siblings = $parentTopicId.nextAll("[data-topic-id]:lt(".concat(READ_AHEAD, ")"));
            $siblings.each(function (idx, s) {
              var siblingId = parseInt(s.getAttribute("data-topic-id"), 10);

              if (!_cached[siblingId]) {
                topicIds.push(siblingId);
              }
            });
            _promise = (0, _ajax.ajax)("/tooltip-previews", {
              data: {
                topic_ids: topicIds
              },
              cache: true
            });

            _promise.then(function (r) {
              if (r && r.excerpts) {
                (0, _object.deepMerge)(_cached, r.excerpts);
              }

              if (_inside) {
                renderTooltip($this, _cached[topicId].excerpt);
              }
            }).catch(function (event) {
              var xhr = event.jqXHR;

              if (xhr && xhr.status === 429) {
                tooltipsRateLimited = true;
                var tryAfter = parseInt(xhr.getResponseHeader && xhr.getResponseHeader("Retry-After"), 10) || 0;
                tryAfter = tryAfter || 0;

                if (tryAfter < 15) {
                  tryAfter = 15;
                }

                _this.rateLimiter = Ember.run.later(_this, function () {
                  tooltipsRateLimited = false;
                }, tryAfter * 1000);
              } // swallow errors - was probably aborted!

            });
          }
        });
        $(this.element).on("mouseleave.discourse-tooltips", selector, function (e) {
          if ((0, _eventFrom.eventFrom)(e) !== "mouse") {
            return;
          }

          cleanDom();
        });
      },
      willDestroyElement: function willDestroyElement() {
        Ember.run.cancel(this.rateLimiter);
        tooltipsRateLimited = false;

        this._super.apply(this, arguments);

        cancel();
        $(this.element).find(selector).off("mouseenter.discourse-tooltips, mouseleave.discourse-tooltips", selector);
      }
    };
  }
});

